export const colors = [
  "rgba(255, 0, 0, 0.3)",
  "rgba(249, 232, 192, 0.9)",
  "rgba(255, 255, 255, 0.9)",
  "rgba(231, 84, 128, 0.7)",
];
export const hoverColors = [
  "rgba(255, 0, 0, 0.5)",
  "rgba(249, 232, 192, 1)",
  "rgba(255, 255, 255, 1)",
  "rgba(231, 84, 128, 0.9)",
];
